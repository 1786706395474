<template>
  <div class="container">
    <div class="infobtn" @click="dialogInfoVisible = true">
      <img style="width: 15px;height: 15px;margin-top: 7px;margin-right: 10px;" src="../../assets/changeInfoBtnImg.png">
      <span style="margin-top: 0px;">Edit My Profile</span>
    </div>
    <div class="btn" @click="dialogVisible = true">Change Password</div>
    <div class="title">My registration information</div>
    <div class="infoItem">
      Name:{{
        student_info.student_lastName +
        student_info.student_givenName
      }}
    </div>
    <div class="infoItem">
      School:{{ student_info.account_name }}
    </div>
    <div class="infoItem">
      Email:{{ student_info.student_email }}
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="813px"
      height="831px"
      center
      :show-close="false"
      custom-class="dialog_class"
      close-on-click-modal
      close-on-press-escape
    >
      <div
        style="font-size: 30px;font-family: DDINAlternateRegular;font-weight: bold;color: #F65D4D;text-align: center;"
      >
        Change Password
      </div>
      <div style="margin: 85px auto 0; width: 572px;">
        <el-input v-model="initial_password" placeholder="Please input your old password" show-password></el-input>
      </div>
      <div style="margin: 35px auto 0; width: 572px;">
        <el-input v-model="new_password" placeholder="Please input your new password" show-password></el-input>
      </div>
      <div style="margin: 35px auto 0; width: 572px;">
        <el-input v-model="new_password_again" placeholder="Please confirm your new password" show-password></el-input>
      </div>
      <div style="margin: 15px auto 0; width: 572px; color: #F65D4D;" v-if="showError">
        {{error}}
      </div>
      <span slot="footer" style="width: 475px;">
        <div
          style="width: 475px;height: 50px;background: #0E4890;border-radius: 50px;color: #FFFFFF;line-height:50px;text-align: center;margin: auto; cursor: pointer; margin-top: 120px; margin-bottom: 100px;"
          @click="changePassword">Confirm to Change</div>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogInfoVisible"
      width="813px"
      height="831px"
      center
      :show-close="false"
      custom-class="dialog_class"
      close-on-click-modal
      close-on-press-escape
    >
      <div
        style="font-size: 30px;font-family: DDINAlternateRegular;font-weight: bold;color: #F65D4D;text-align: center;"
      >
        Edit Profile
      </div>
      <div style="margin: 85px auto 0; width: 572px;">
        <span>Last Name:</span>
        <el-input style="margin-top: 10px;" v-model="student_info.student_lastName" placeholder="Your Last Name" ></el-input>
      </div>
      <div style="margin: 35px auto 0; width: 572px;">
        <span>Given Name:</span>
        <el-input style="margin-top: 10px;" v-model="student_info.student_givenName" placeholder="Your Given Name"></el-input>
      </div>
      <!-- <div style="margin: 35px auto 0; width: 572px;">
        <el-input v-model="new_password_again" placeholder="请再次输入新密码" show-password></el-input>
      </div> -->
      <!-- <div style="margin: 15px auto 0; width: 572px; color: #F65D4D;" v-if="showError">
        {{error}}
      </div> -->
      <span slot="footer" style="width: 475px;">
        <div
          style="width: 475px;height: 50px;background: #0E4890;border-radius: 50px;color: #FFFFFF;line-height:50px;text-align: center;margin: auto; cursor: pointer; margin-top: 120px; margin-bottom: 100px;"
          @click="changeInfo">Confirm to Change</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { changePassword, getStudentInfoByID, updateStudentName } from '../../api'
import {
  getUserId
} from '../../utils/store'
import '../../assets/common/font.css'
export default {
  data () {
    return {
      dialogVisible: false,
      dialogInfoVisible: false,
      initial_password: '',
      new_password: '',
      new_password_again: '',
      showError: false,
      error: '',
      student_info: {}
    }
  },
  mounted () {
    this.getStudentInfoByID()
  },
  methods: {
    getStudentInfoByID () {
      getStudentInfoByID(getUserId()).then((res) => {
        this.student_info = res.data.data
      })
    },
    toApply () {
      this.$router.push({
        path: '/apply'
      })
    },
    changePassword () {
      changePassword(getUserId(), this.initial_password, this.new_password, this.new_password_again).then((res) => {
        console.log(res, 9999)
        if (res.data.code === 0) {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          this.dialogVisible = false
          this.initial_password = ''
          this.new_password = ''
          this.new_password_again = ''
          this.showError = false
          this.error = ''
        } else {
          this.error = '*' + res.data.msg
          this.showError = true
        }
      })
    },
    changeInfo () {
      if (this.student_info.student_lastName != '' && this.student_info.student_givenName != '') {
        updateStudentName(getUserId(), this.student_info.student_lastName, this.student_info.student_givenName).then((res) => {
          this.getStudentInfoByID()

          this.$message({
            message: res.data.msg,
            type: 'success'
          })

          this.dialogInfoVisible = false
        })
      } else {
        this.$message({
          message: 'Cannot be blank',
          type: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .container {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding-top: 11%;
    padding-left: 4%;
    padding-right: 4%;
    overflow-y: scroll;
    font-family: "DDINAlternateRegular";
    .btn {
      background: #0e4890;
      width: 170px;
      height: 34px;
      border-radius: 20px;
      line-height: 34px;
      text-align: center;
      color: #fff;
      position: absolute;
      top: 11%;
      right: 26px;
      cursor: pointer;
    }
    .infobtn{
      background: #FFB414;
      width: 190px;
      height: 34px;
      border-radius: 20px;
      line-height: 34px;
      text-align: center;
      color: #fff;
      position: absolute;
      top: 11%;
      right: 206px;
      cursor: pointer;
    }
    .title {
      width: 100%;
      margin: 4% auto 0 auto;
      font-weight: bold;
      font-size: 30px;
      font-family: "DDINAlternateRegular";
      font-weight: bold;
      color: #0E4890;
    }
    .infoItem {
      width: 100%;
      box-sizing: border-box;
      margin: 30px auto;
      font-weight: bold;
      padding: 0 10px;
      border-left: 5px solid #ffb414;
      font-size: 22px;
      font-family: "DDINAlternateRegular";
      font-weight: 400;
      color: #A4A4A4;
    }
  }
</style>
